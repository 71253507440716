






































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
    components: {
        PagePresetFullWidth: () => import('@/layouts/presets/FullWidth.vue'),
        SideNav: () => import('@/layouts/parts/SideNav.vue'),
    },
})
export default class PagePresetLegal extends Vue {
    @Getter('pageLoading') pageLoading!: boolean;
}
